.certificates__container {
  width: 750px;
}

.certificates__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.5rem 2rem;
  border-radius: 1.5rem;
  margin-bottom: var(--mb-3);
}

.certificates__img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

/* swiper styles */
.swiper-pagination-bullet {
  background-color: var(--text-color) !important;
}

.swiper-pagination-bullet active {
  background-color: var(--title-color) !important;
}

@media screen and (max-width: 992px) {
  .certificates__container {
    width: initial;
  }

  .certificates__card {
    padding: 1.25rem 1.5rem;
  }
}
