.work-experience {
  max-width: 750px;
  background-color: #f2f2f2;
  color: #333;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 4rem;
  border-radius: 1.25rem;
  margin: 0 auto 40px;
}

.company-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
}

.company-logo {
  width: 50px;
  height: 30px;
}

.company-name {
  font-size: 1.4em;
  font-weight: bold;
  color: #2c3e50;
  margin: 0;
}

.job-title {
  font-size: 1.2em;
  color: #7f8c8d;
  margin: 5px 0;
}

.work-period {
  font-size: 1em;
  color: #95a5a6;
  margin: 0;
}

.job-details {
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.responsibilities {
  list-style-type: disc;
  padding-left: 20px;
}

.responsibilities li {
  margin-bottom: 12px;
  font-size: 1.05em;
  color: var(--text-color);
}

.responsibilities li strong {
  color: #333;
  font-weight: 600;
}

@media (max-width: 768px) {
  .work-experience {
    padding: 15px;
  }

  .logo-container {
    width: 40px;
    height: 40px;
  }

  .company-name {
    font-size: 1.3em;
  }

  .job-title {
    font-size: 1.1em;
  }

  .responsibilities {
    padding-left: 15px;
  }
}
